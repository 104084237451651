import {useEffect} from 'react'
import '../styles.css'
import Bridgestone from '../images/icon2.svg'
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function About2() {

    const control = useAnimation()
    const [ref, inView] = useInView()
  
    const caseVariant = {
      visible: { opacity: 1, scale: 1},
      hidden: {  opacity: 0 , scale: 0.8 },
      
    }
    useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

  return (
    <div className=' pb-52'>
		 
		 <h1 className=" text-center mb-24 text-4xl font-extrabold 
    					  md:text-5xl lg:text-6xl mt-32 w-11/12 m-auto lg:w-3/4 pb-4">
        Develop campaigns that will truly resonate with your target audience.
               
         </h1>
		 
    <div> 
    <p className="text-lg font-normal text-gray-300 lg:text-xl
    			  mt-20 lg:w-2/3 m-auto w-5/6">
        At Mediology, we welcome challenges with open arms. Embracing innovation and pushing 
        limits are fundamental to our creative approach. We're passionate about developing ideas 
        that inspire change, ignite conversations, and bring communities together.
		</p>
		</div>
        <motion.div
            ref={ref}
            variants={caseVariant}
            initial="hidden"
            animate={control}
            transition={{ duration: 0.5 }}
            >
    <div className="container px-6 py-10 mx-auto pt-20">
        <div className="lg:-mx-6 lg:flex lg:items-center">
                 <img  className='rounded-3xl w-4/6 mx-auto lg:w-1/5 '  src={Bridgestone} alt="Bridgestone" />
            <div className="mt-8 lg:w-1/2 lg:px-6 lg:mt-0">
              

                <h1 className="text-4xl font-semibold lg:text-4xl">
                Chase Your Dream
                </h1>

                <p className="max-w-lg mt-6 text-white ">
                We partnered with 3 iconic Olympians who have not only achieved Olympic glory, but have overcome 
                extreme adversity on their journeys to success.
                    <br></br><br></br>
                    Each Ambassador’s story of struggle, and the inspiration it brings, lie at the heart of the campaign.
                <br></br><br></br>
                South Africa was the 2nd best performing market globally, against set targets.
                <br></br><br></br>
                Bridgestone (72%) along with Coca-Cola (78%) stand out as the most spontaneous brands 
                mentioned when respondents were asked to indicate Olympic partners.
                </p>

                <h1 className="mt-6 text-2xl font-semibold">Client</h1>
                <p className="text-gray-300 text-xl">Bridgestone</p>  
            </div>
            
        </div>
        
    </div>
    </motion.div>
        </div>
        
  )
}

export default About2