import {React, useRef, useEffect} from 'react'
import { useTransform, useScroll, motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import Icon from '../images/icon.svg'
import logo from '../logo.png'

import '../styles.css'

function Logosplash() {

  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", " end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  const control = useAnimation()
  const [ref, inView] = useInView()

  const caseVariant = {
    visible: { opacity: 1, scale: 1},
    hidden: {  opacity: 0 , scale: 0.8 },
    
  }
  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  


  return (
    <motion.div useRef={targetRef} 
                style={{ scale, paddingBottom: '50px' ,
                marginLeft:'auto',
                marginRight:'auto',
                 }} >
        
            <div style={{display:'center',
                marginLeft:'auto',
                marginRight:'auto',
                marginTop:'-900px',   
                width: '40%',
                
                 }}>
                  <img  src={logo} alt="Logo" />
        
                </div>
         <div>
         <h1 className=" text-center mb-2 text-5xl font-extrabold 
    					  md:text-5xl lg:text-6xl mt-0 w-11/12 m-auto lg:w-3/4 pb-0 
                ">
                          Revolutionise your media game</h1>
         </div>
         <div>
         <p className="text-lg font-normal text-gray-300 lg:text-xl
    			  mt-2 lg:w-2/3 m-auto w-5/6 pb-12">
              Welcome to Mediology, a media-led, creatively-inspired agency whose 
              approach is grounded in a deep understanding of human behaviour. 
              We craft integrated campaigns that resonate with audiences and achieve 
              solutions-driven objectives.
              <br></br><br></br>
              We collaborate with socially responsible and sustainable-minded brands 
              committed to creating a positive impact on society.
        </p>
         </div>

         <motion.div
            ref={ref}
            variants={caseVariant}
            initial="hidden"
            animate={control}
            transition={{ duration: 0.5, delay: 0 }}
            >
                
         <div className="container px-6 py-10 mx-auto">
        <div className="lg:-mx-6 lg:flex lg:items-center">
                 <img  className='w-4/6 mx-auto lg:w-1/6 pb-12'  src={Icon} alt="Icon" />
            
            <div className="mt-8 lg:w-1/2 lg:px-6 lg:mt-0">
              

                <h1 className="text-4xl font-semibold lg:text-4xl">
                United We Shall Stand
                </h1>

                <p className="max-w-lg mt-6 text-white ">
                A “once in a lifetime” event ignited the historic opening of the 2010 FIFA World Cup.
                    <br></br><br></br>
                    On a budget of R 2 million, with tight collaboration between several agencies and media partnerships, 
                    we created an event that ignited the nation and resulted in a powerful campaign 
                    valued at R 57,4 million.
                <br></br><br></br>
                “I only recall at the release of Nelson Mandela where we saw people coming together like this …”
                </p>

                <h1 className="mt-6 text-2xl font-semibold">Client</h1>
                <p className="text-gray-300 text-xl">Southern Sun</p>  
            </div>
        </div>
    </div>
   
        
         <div className='m-auto w-fit pt-12  lg:pl-32'>
          {/*
          <motion.div
          whileHover={{ scale: 1.2 }}
          >
               <button className='button '>Talk to us</button>
          </motion.div>
             */} 
            </div>
            </motion.div>
        </motion.div>
    
  )
}

export default Logosplash