import React from 'react'

function Services() {
  return (
   
<section className="pt-40 pb-4 lg:pt-[120px] lg:pb-[90px]">
  <div className="container mx-auto ">
    <div className="-mx-4 flex flex-wrap  ">
      <div className="w-full px-4">
        <div className="mx-auto mb-12  lg:mb-20">
        
          <h1
            className=" mb-4 font-bold text-8xl mx-auto text-center "
          >
            Our Services
          </h1>
          <p className="text-lg font-normal text-gray-300 lg:text-xl
    			  mt-20 lg:w-2/3 m-auto w-5/6 ">
          Our professional teams are ready to address your business challenges. 
          They bring a diverse range of expertise and creative thinking to every 
          phase of the project, ensuring a holistic approach is taken.
          As a full-service agency we offer a comprehensive range of services to help our 
          clients’ with their advertising and marketing needs.
          </p>
        
        </div>
      </div>
    </div>
    <div className="-mx-4 flex flex-wrap  p-10">
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
          
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">
          Communication Strategy
          </h1>
          <p className="text-gray-300">
          Communication is the key to success in today's fast-paced business environment
          and having a well-defined communication strategy is crucial for achieving your 
          business goals. At Mediology, we can help you create a clear and effective communication 
          strategy that will set your business up for success.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
              
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">
          Channel Strategy
          </h1>
          <p className="text-gray-300">
          Designed to help you identify the most effective channels to reach your target audience, 
          whether that's through social media, email marketing, advertising, or other means. 
          We'll work with you to understand your business goals and target audience, and develop 
          a customised strategy that maximizes your ROI and reaches your audience where they are.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">Digital Marketing</h1>
          <p className=" text-gray-300">
          At Mediology, we combine our expertise in design and creative services with a deep 
          understanding of digital marketing strategies. By integrating these services seamlessly, 
          we help you establish a strong online presence, engage your target audience effectively, 
          and achieve your marketing goals.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">Competitive Analysis</h1>
          <p className=" text-gray-300">
          Are you curious about what your competitors are doing in the market and how you 
          can stand out from them? Let us help you with a full competitive creative and media analysis.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
            
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">
          Insight/Trends Gathering
          </h1>
          <p className=" text-gray-300">
          We use cutting-edge technology and expert analysis to collect and analyse data 
          from various sources, allowing us to identify emerging trends and predict future 
          market movements. With our insights, you'll be able to make smarter decisions and 
          stay ahead of the competition.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">Research</h1>
          <p className=" text-gray-300">
          At Mediology, we understand that every business is unique, with its own set of 
          challenges, opportunities, and goals. That's why we offer both standard and 
          bespoke research solutions to meet your specific needs.
          </p>
        </div>
        
      </div>
    </div>
  </div>
  


  <div className="container mx-auto ">
    <div className="-mx-4 flex flex-wrap  ">
      <div className="w-full px-4">
      
      </div>
    </div>
    <div className="-mx-4 flex flex-wrap  p-10">
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
          
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">
          Data Analysis
          </h1>
          <p className="text-gray-300">
          We'll use advanced analytics tools and techniques to identify trends and 
          patterns in your data, and provide recommendations on how you can use this 
          information to optimise your business operations.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
              
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">
          Media Verification and Analytics
          </h1>
          <p className="text-gray-300">
          Are you struggling to measure the effectiveness of your media campaigns? 
          Let us help you with media verification and analytics.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">Implementation Planning</h1>
          <p className=" text-gray-300">
          You can be confident that your strategy is being executed in a way that maximizes 
          your chances of success. Contact us today to learn more about our services and 
          how we can help you achieve your business goals.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">Media Negotiations and Buying</h1>
          <p className=" text-gray-300">
          We help you get the best possible rates for your media placements, whether that's 
          through traditional channels like TV and radio, or through digital channels like 
          social media and programmatic advertising. We'll work with you to understand your 
          target audience and develop a media plan that reaches them where they are.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
            
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">
            Sponsorship Evaluations
          </h1>
          <p className=" text-gray-300">
          Are you considering investing in a sponsorship opportunity, but not sure if it's worth the investment? 
          Let us help you with sponsorship evaluations at Mediology.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
        >
          <div
            className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
          >
           
          </div>
          <h1 className="text-dark mb-3 text-2xl font-semibold">Media Audits</h1>
          <p className=" text-gray-300">
          Our experienced professionals will conduct a thorough analysis of your media spend, 
          including reviewing your contracts, evaluating your media placements, and analyzing 
          your audience reach and engagement. We'll provide you with a detailed report that 
          highlights any discrepancies or inefficiencies, and identifies opportunities for 
          improvement.
          </p>
        </div>
        
      </div>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
                  >
                    <div
                      className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
                    >
                    
                    </div>
                    <h1 className="text-dark mb-3 text-2xl font-semibold">Media Training</h1>
                    <p className=" text-gray-300">
                    With our media training services, you can be confident that your team is 
                    equipped to represent your brand effectively and engage with the media in 
                    a way that best promotes your brand's message.
                    </p>
                  </div>
            </div>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
                  >
                    <div
                      className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
                    >
                    
                    </div>
                    <h1 className="text-dark mb-3 text-2xl font-semibold">Branding & Design</h1>
                    <p className=" text-gray-300">
                    Through our collaborative approach, we work closely with you to understand your brand's 
                    vision and aspirations. We then apply our expertise in design principles, market trends, 
                    and consumer insights to create unique and impactful brand identities that effectively 
                    represent your business.
                    </p>
                  </div>
            </div>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    className="mb-8 rounded-[20px]  p-10  md:px-7 xl:px-10"
                  >
                    <div
                      className="bg-primary mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
                    >
                    
                    </div>
                    <h1 className="text-dark mb-3 text-2xl font-semibold">Advertising Campaigns</h1>
                    <p className=" text-gray-300">
                    We excel in crafting visually impactful advertising campaigns tailored to diverse media 
                    channels. Our team has a deep understanding of the unique characteristics and requirements of each platform, 
                    enabling us to create compelling campaigns that resonate with your target audience. 

                    </p>
                  </div>
            </div>
      
    </div>
    
  </div>


</section>


  )
}

export default Services
