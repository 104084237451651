import React from 'react'
import { useTransform, useScroll, motion, useSpring } from "framer-motion";
import '../styles.css'


function Heading() {
  return (
    
    <motion.div
    initial={{ opacity: 0, y: '-400px' }}
    animate={{ opacity: 1, y: '-150px' }}
    transition= {{ duration: 2 }}
    
    >
     <div className='header items-center justify-center'>
     <h2  className=' text-center text-8xl lg:text-10xl pt-40 pb-10  lg:pt-96 m-auto 
                      lg:w-5/6 sm:w-1/2 md:w-full
                      lg:leading-tight leading-normal font-bold'>
      WHERE LOGIC MEETS MAGIC</h2>
     </div>
     
    
    
  </motion.div>
  )
}

export default Heading