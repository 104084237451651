
import { useRef, useEffect } from 'react'
import { Canvas, } from '@react-three/fiber'
import { motion, useScroll, useTransform, AnimatePresence } from "framer-motion";
import Navbar from './components/Navbar'
import Heading from './components/Heading'
import Logosplash from './components/Logosplash'
import Newsletter from './components/Newsletter';
import Stars from './components/Stars'
import Testimonials1 from './components/Testimonials1';
import Testimonials2 from './components/Testimonials2';
import Testimonials3 from './components/Testimonials3';
import About from './components/About'
import Awards from './components/Awards';
import About2 from './components/About2';
import Culture from './components/Culture';
import Services from './components/Services';
import Clients from './components/Clients';
import Splash from './components/Splash';
import Splash2 from './components/Splash2';
import Splash3 from './components/Splash3';
import Splash4 from './components/Splash4';
//import Carousel from './components/Carousel';
import Cases from './components/Cases';
import Cases2 from './components/Cases2';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer'
//import Bezier from './components/Bezier';

import './styles.css'


export default function App() {


  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const Ref = useRef(null);
  const { scrollYProgress } = useScroll()
 
  const scale = useTransform(scrollYProgress, [1, 0.5], [0, 1]);
  const scaleLogo = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const scaleT = useTransform(scrollYProgress, [0, 0.1], [0, 4]);



  return (
      <div className='' style={{overflow: scroll}}>
                        <Navbar />
            <div>
          < div>
                        <Heading/>
          </div>
                        <Canvas style={{ position: 'relative', top: -180, 
                        left: 0, pointerEvents: 'none', height:'1800px' }}  
                        camera={{ position: [0, 0, 1] }}>
                        <Stars />
                        </Canvas>
            </div>

            <div>
                         <Logosplash/>
            
            </div>
            
           

                <div className='lg:flex mx-auto w-10/12 pb-32 pt-32'>
                        <Testimonials1/>
                        <Splash />
                </div>        
           
                  <div>
                  
                  </div>    

            <div className='pt-0 sm:pt-12 pb-36 '>
                         <Newsletter/>
            </div>
    
          <div>
                        <About />
          </div>
        
          <div className='mt-10'>
                         <About2/>
          </div>
            <motion.div ref={Ref} 
                        style={{scale}}
                        className='lg:flex mx-auto w-10/12 pb-32 pt-32'>
                        <Splash2/>
                        <Testimonials2/>
            </motion.div>
          <div>
                        <Culture/>
          </div>
          <div className='lg:flex mx-auto w-10/12 pb-32 pt-32'>
          <Testimonials3/>  
                        <Splash4/>
                        
          
          </div>
              
          <div>
                        <Services/>
          </div>
            
          
          <div> 
                        <Awards />
          </div>

          <div className='lg:flex mx-auto w-10/12 pb-32 pt-32'>
                        <Splash3/>
                        <Testimonials/>
          </div>

          <div>
                        <Cases/>  
                        <Cases2/>  
          </div>
          <div>
                        <Clients/>
          </div>
     
          <div className=' mt-20 mb-20'>
                        <Contact/>
         </div>
         
          <div>
                        <Footer />
        </div>
    
    </div>
    
    
  )
}

