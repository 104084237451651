import {React, useRef} from 'react'
import { useTransform, useScroll, motion, useSpring } from "framer-motion";

function Testimonials3() {

    const targetRef = useRef<HTMLDivElement | null>(null);
    const { scrollYProgress } = useScroll({     
        target: targetRef,
        
    });

    const scale = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
 

  return (
    <section useRef={targetRef}   className="testimonials">
	

	
		

  <motion.div 
                
                style={{ scale}}
                className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:pt-32">
     

     <figure className="max-w-screen-md mx-auto">
          <svg className="h-12 mx-auto mb-3 text-gray-400 " viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
          </svg> 
          <blockquote>
              <p className="text-2xl font-medium text-white">
				"Mediology is always on the look-out for innovative spaces for our brand and I 
                appreciate that they will always respectfully challenge our thinking, both with 
                conventional and more creative wisdom."</p>
          </blockquote>
          <figcaption className="flex items-center justify-center mt-6 space-x-3">
              <div className="flex items-center divide-x-2 divide-gray-500 ">
                  <div className="pr-3 text-xl text-white">Kirby Gordon</div>
                  <div className="pl-3 text-lg font-light text-gray-300">
										Chief Marketing Officer<br></br>
										FlySafair</div>
              </div>
          </figcaption>
      </figure>
 
  </motion.div>

		
		</section>
  )
}

export default Testimonials3